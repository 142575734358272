import { createChatBotMessage } from "react-chatbot-kit";
import { generateHtmlMessage, getMessage } from '../Utilities';
import Image from "../../../assets/img";
import GifCool from "../Template/GifCool";


import WidgetOptions from "../widgets/WidgetOption";


const botName = "Virtual booth";

const CustomBotAvatar = (props) => {
  return (
    <div className="react-chatbot-kit-chat-avatar-container">
      <img
        src={Image.logoBotImg}
        className="react-chatbot-kit-chat-avatar-image" alt=""
      />
    </div>
  );
};




const config = {
  initialMessages: [
    createChatBotMessage( getMessage(0) ?  generateHtmlMessage(getMessage(0).message) : 'Super ✌️, merci pour ta photo ! 🤩', {
      withAvatar: true,
      // widget : getMessage(0).widget ?  getMessage(0).widget.name : 'GifCool',
      infoMessage : getMessage(0)
    }),
    createChatBotMessage(generateHtmlMessage(getMessage(1).message),
    { 
      key : getMessage(1).ordre,
      required : false,
      withAvatar: true,
      delay: 2000,
      infoMessage : getMessage(1)
   })
  ],
  botName: botName,
 
  customComponents: {
    botAvatar: (props) => <CustomBotAvatar {...props} />,
  },
  widgets: [
    {
      widgetName: "GifCool",
      widgetFunc: (props) => <GifCool {...props} />,
    },
    {
      widgetName: "WidgetOptions",
      widgetFunc: (props) => <WidgetOptions {...props} />,
    },
  ],
  runInitialMessagesWithHistory : true,
};

export default config;
