import React from "react";
// import { useNavigate } from "react-router-dom";
import {Container, Grid, Typography, Button } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
// import { useStyle } from "./Contenu.style";
// import classNames from "classnames";

export default function Cgj (props){
  // const navigate = useNavigate();
  // const classes = useStyle();
  return (
    <Container className="App" maxWidth="sm" style={{ position: "relative" }}> 
    {localStorage.getItem('condition_utilisation')}
    </Container>
  );
};
