import React, { useEffect, useState } from "react";
import {Container, TextField} from "@mui/material";
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import {saveResponse, uploadData, validateEmail} from '../Chatbot/Utilities';
// import  { useNavigate } from 'react-router-dom';
import {Circles} from 'svg-loaders-react'
import Thanks from "./Thanks";
import {generateHtmlMessage} from "../Chatbot/Utilities";


// export default function Form() {
export default function Form() {

    const [errorEmail, setErrorEmail] = useState(false);
    const [errorRegelement, setErrorRegelement] = useState(false)
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showMerci, setShowMerci] = useState(false);
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        const configuration = JSON.parse(localStorage.getItem("configuration"));
        if (configuration.chatbot_json) {
            setMessages(configuration.chatbot_json);
        }
    }, []);

    const handleInput = () => {
        const emailVal = document.getElementById('email').value;
        if (!validateEmail(emailVal)) {
            setErrorEmail(true);
            setDisableSubmit(true);

            return false;
        } else {
            setErrorEmail(false);
            setDisableSubmit(false);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        window.dataChamps = [];

        const onlyInputs = document.querySelectorAll('#idForm input');
        onlyInputs.forEach(input => {
            if (input.type === 'radio' && input.checked) {
                saveResponse(input.getAttribute('champid'), input.value, input.type);
            } else if (input.type !== 'radio') {
                if (input.type === 'email') {
                    if (validateEmail(input.value)) {
                        saveResponse(input.getAttribute('champid'), input.value, input.type);
                    } else{
                        setErrorEmail(true);
                        setDisableSubmit(true);

                        return false;
                    }
                } else {
                    saveResponse(input.getAttribute('champid'), input.value, input.type);
                }
            }
        });

        setLoading(true);
        setDisableSubmit(true);
        await uploadData();
        setLoading(false);
        setShowMerci(true);
    }


    
    return (
        <>
            {!showMerci ? 
            (
                <Container className="containerForm" maxWidth="sm" style={{ position: "relative" }}>
                    <div className="containerFormBlock" style={{ position: "relative" }}>
                        <h2 className="titreForm">Votre information</h2>
                        <form id="idForm" onSubmit={handleSubmit}>
                            { messages.length > 0 ?  messages.map((message, index) => {
                                if (message.inputType === 'email') {
                                    return (
                                        <FormControl key={index} variant="standard" fullWidth margin="normal" >
                                                <TextField
                                                    label={generateHtmlMessage(message.message)}
                                                    id="email"
                                                    name="email"
                                                    error={errorEmail}
                                                    helperText={errorEmail ? 'E-mail invalide' : ''}
                                                    onChange={handleInput}
                                                    variant="standard"
                                                    inputProps={{champid: message.champ_id}}
                                                />
                                            </FormControl>
                                        )
                                } else if (message.inputType === 'telephone') {
                                    return (
                                        <FormControl  key={index}  variant="standard" fullWidth margin="normal" >
                                                    <TextField
                                                        label={generateHtmlMessage(message.message)}
                                                        id="telephone"
                                                        name="telephone"
                                                        variant="standard"
                                                        inputProps={{champid: message.champ_id}}
                                                    />
                                        </FormControl>
                                    )
                                } else if (message.inputType === 'options') {
                                    if (message.inputTypeOption === "radio") {
                                        return (
                                            <FormControl key={index} margin="normal" fullWidth>
                                                <FormLabel id={"id-option-" + message.id}>{generateHtmlMessage(message.message)}</FormLabel>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby={"id-option-" + message.id}
                                                    name={"optin-" + message.id}
                                                >
                                                    {message.options.map((option, o) => {
                                                     return <FormControlLabel key={o}  value={option.value} control={<Radio value={option.value} inputProps={{champid: message.champ_id}} required />} label={option.label} />
                                                    })}
                                                </RadioGroup>
                                            </FormControl>
                                        )
                                    } else {
                                        return (
                                            <FormControl key={index} margin="normal" fullWidth>
                                                <FormLabel id={"id-option-" + message.id}>{generateHtmlMessage(message.message)}</FormLabel>
                                                {message.options.map((option, o) => {
                                                    return <FormControlLabel key={o} value={option.value} control={<Checkbox value={option.value} inputProps={{champid: message.champ_id}} required />} label={option.label} />
                                                })}
                                            </FormControl>
                                        )
                                    }
                                }
                            }) : 'Chargement ...'} 
                        
                            {messages.length > 0 ?  <Button disabled={disableSubmit} type="submit" fullWidth variant="contained" startIcon={!loading && <SendIcon />}>
                                {
                                    loading && (<Circles color="#FFF" className="loadingSend" />)
                                }
                                Envoyer
                            </Button> : ''}
                        </form>
                    </div>
                </Container>
            ) 
            : 
            (<Thanks />)
            }
        </>
    )
}
