import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles((theme) => ({
  gridTopImage: {
    [theme.breakpoints.up('lg')]: {
      height: "auto",
      textAlign: "center",
    },
    [theme.breakpoints.down('md')]: {
      height: "auto",
      textAlign: "center",
      marginBottom:"15px",
    },
  },
  boxContainer: {
    backgroundColor: "#fff",
    padding: "0px 0 70px",
    borderRadius: "5px",
  },
  gridMiddleImage: {
    padding: "0 20px !important",
    marginBottom: "10px",
  },
  typography: {
    textAlign: "center",
    padding: "0 20px !important",
  },
  gridButton: {
    display: "flex",
    justifyContent: "center",
    marginTop: "-50px",
    marginBottom: "10px"
  },
  goButton: {
    border: "5px solid #dcedff",
    padding: "20px 25px",
    fontWeight: "bold",
    fontSize: "1.2rem",
    borderRadius: "8px",
  },
  gridLastImage: {
    display: "flex",
    flexDirection: "column !important",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "25px"
  },
  typographyLastImage: {
    color: "#e2001a",
    fontWeight: "bold !important"
  },
  menuBottom: {
    display: "flex",
    backgroundColor: "lightgray",
    padding: "18px",
    justifyContent: "space-around",
    width: "100%",
    bottom: "0"
  },
  menuTypoBottom: {
    
    fontSize: "0.9rem !important",
    cursor: "pointer",
    '&:hover': {
      textDecoration: "underline",
   },
  }
}));
