import {useRef, useEffect, forwardRef, useImperativeHandle} from "react";

import {isMobile} from 'react-device-detect';

// export default function Camera(props) { const Camera = forwardRef((props,
// ref) => {
const Camera = (props, ref) => {
    const devices = useRef(null);
    const localStream = useRef(null);
    const camera = useRef(null);
    const mounted = useRef(false);
    const canvasRef = useRef(null);

    useEffect(() => {
        const init = async() => {
            if (!devices.current) {
                await loadDevices(); // load the camera devices list when the component is mounted
            }
            if (props.isActive === true) {
                playWithDesired();
            }
            mounted.current = true;
        };
        init();
    }, [])

    useEffect(() => {
        if (props.isActive === true && localStream.current && mounted.current === true) {
            playWithDesired();
        }
    }, [props.desiredCamera, props.desiredResolution, props.facingMode]);

    useImperativeHandle(ref, () => ({

        getPicture(options) {
            const canvas = canvasRef.current;
            canvas.width = options.widthSource;
            canvas.height = options.heightSource;
            if (isMobile) {
                canvas.width = options.heightSource;
                canvas.height = options.widthSource;
            }

            canvas
                .getContext("2d")
                .drawImage(camera.current, 0, 0, canvas.width, canvas.height);

            var data = canvas.toDataURL('image/png')

            return data;
        }
    }));

    const loadDevices = async() => {
        let stream = null;
        try {
            const constraints = {
                video: true,
                audio: false
            };
            stream = await navigator.mediaDevices.getUserMedia(constraints);
    
            const mediaDevices = await navigator
                .mediaDevices
                .enumerateDevices();
            let cameraDevices = [];
            for (let i = 0; i < mediaDevices.length; i++) {
                let device = mediaDevices[i];
                if (device.kind === 'videoinput') { // filter out audio devices
                    cameraDevices.push(device);
                }
            }
            devices.current = cameraDevices;
            const tracks = stream.getTracks();
            for (let i = 0; i < tracks.length; i++) {
                const track = tracks[i];
                console.log(track.getSettings());
                track.stop(); // stop the opened camera
            }

            console.log(mediaDevices.length);
            props.setCountCamera(mediaDevices.length);
        } catch (err) {
            /* handle the error */
            console.log('error be');
            // props.setErrorCamera('Une erreur est survenue.');
            handleError(err);
        }
    }

    const playWithDesired = async() => {
        if (!devices.current) {
            await loadDevices(); // load the camera devices list if it hasn't been loaded
        }
        let desiredDevice = getDesiredDevice(devices.current)

        if (desiredDevice) {
            let options = {};
            options.deviceId = desiredDevice;
            if (props.desiredResolution) {
                options.desiredResolution = props.desiredResolution;
                document.getElementById('cadreSize').textContent = props.desiredResolution.width + 'x' + props.desiredResolution.height;
            }
            if (props.facingMode) {
                options.facingMode = props.facingMode;
            }
            play(options);
        } else {
            props.setErrorNoCamera(true);
        }
    }

    const getDesiredDevice = (devices) => {
        var count = 0;
        var desiredIndex = 0;
        if (devices) {
            for (var i = 0; i < devices.length; i++) {
                var device = devices[i];
                var label = device.label || `Camera ${count++}`;
                if (props.desiredCamera) {
                    if (label.toLowerCase().indexOf(props.desiredCamera.toLowerCase()) != -1) {
                        desiredIndex = i;
                        break;
                    }
                }
            }

            if (devices.length > 0) {
                return devices[desiredIndex].deviceId; // return the device id
            } else {
                return null;
            }
        }
    }

    const play = (options) => {
        stop(); // close before play
        var constraints = {};

        if (options.deviceId) {
            constraints = {
                video: {
                    deviceId: options.deviceId
                },
                audio: false
            }
        }

        if (options.facingMode) {
            delete constraints["video"]["deviceId"];
            constraints["video"]["facingMode"] = {
                exact: options.facingMode
            };
        }

        if (options.desiredResolution) {

            // if (isMobile) {
            //     constraints["video"]["width"] = {
            //         // min : options.desiredResolution.width,
            //         ideal: options.desiredResolution.height,
            //     };
            //     constraints["video"]["height"] = {
            //         // min : options.desiredResolution.height,
            //         ideal: options.desiredResolution.width,
            //     };

            // // } else {
                constraints["video"]["width"] = {
                    // min : options.desiredResolution.width,
                    ideal: options.desiredResolution.width,
                };
                constraints["video"]["height"] = {
                    // min : options.desiredResolution.height,
                    ideal: options.desiredResolution.height,
                };
            // }
            
            if (isMobile) {
                let ratio = options.desiredResolution.height / options.desiredResolution.width;
                constraints["video"]["aspectRatio"] = {
                    exact: ratio
                };
            } else {
                let ratio = options.desiredResolution.width / options.desiredResolution.height;
                constraints["video"]["aspectRatio"] = {
                    exact: ratio
                };
            }

            

            // if (isMobile) {
            //     document
            //         .getElementById('video')
            //         .setAttribute('width', options.desiredResolution.width);
            //     document
            //         .getElementById('video')
            //         .setAttribute('height', options.desiredResolution.height);
            // }
        }
        

        console.log(constraints);

        navigator
            .mediaDevices
            .getUserMedia(constraints)
            .then(function (stream) {
                localStream.current = stream;
                // Attach local stream to video element
                camera.current.srcObject = stream;
                // stream.getTracks().forEach(function(track) {
                //     console.log(track.getSettings());
                // })
            })
            .catch(function (err) {
                if (options.facingMode) { // facing mode not supported on desktop Chrome
                    delete options["facingMode"];
                    play(options);
                } else {
                    handleError(err);
                    console.error('getUserMediaError', err, err.stack);
                }
            });
    }

    const stop = () => {
        try {
            if (localStream.current) {
                const stream = localStream.current;
                const tracks = stream.getTracks();
                for (let index = 0; index < tracks.length; index++) {
                    const track = tracks[index];
                    track.stop();
                }
                if (props.onClosed) {
                    props.onClosed();
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    const onCameraOpened = () => {
        console.log("onCameraOpened");
        if (props.onOpened) {
            props.onOpened(camera.current, getCurrentCameraLabel());
            document.getElementById('valueNameCamera').textContent = getCurrentCameraLabel();
            document
                .getElementById('video')
                .classList
                .add('loaded');
            props.makeAllLoader(true);
        }
    }

    const getCurrentCameraLabel = () => {
        try {
            if (localStream.current) {
                const stream = localStream.current;

                let {width, height} = stream
                    .getTracks()[0]
                    .getSettings();
                // alert(`${width}x${height}`); // 640x480

                document.getElementById('valueWidth').textContent = `${width}x${height}`;


                return stream.getTracks()[0].label;
            }
        } catch (error) {
            return "";
        }
    }

    const handleError = (error) => {
        console.log(error.name);
        if (error.name === 'OverconstrainedError') {
            props.setErrorCamera(`The resolution is not supported by your device.`);
        } else if (error.name === 'NotAllowedError') {
            props.setErrorCamera('Permissions have not been granted to use your camera and ' +
            'microphone, you need to allow the page access to your devices in ' +
            'order for the demo to work.');
        }
      }

    return (
        <div
            style={{
            position: "relative",
            width: "100%",
            height: "100%",
            left: 0,
            top: 0
        }}>
            <div className="contentarea">
                <div className="camera">
                    <video
                        id="video"
                        style={{
                            objectFit: "cover",
                            left: 0,
                            top: 0
                        }}
                        ref={camera}
                        muted
                        autoPlay={true}
                        playsInline={true}
                        onLoadedData={onCameraOpened}>Le flux vidéo n'est pas disponible.</video>
                </div>
            </div>
            <canvas id="bufferCanvas"></canvas>
            <canvas
                ref={canvasRef}
                style={{
                display: "none"
            }}
                id="canvas"></canvas>
            <img alt="car" id="capturedImage"/>


            <div className="d-none">
                <div id="valueNameCamera"></div>
                <div id="valueWidth"></div>
                <div id="sizeCadre">Cadre hole size <div id="cadreSize"></div></div>
            </div>
        </div>
    )

};

export default forwardRef(Camera);