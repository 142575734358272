import { useNavigate } from "react-router-dom";
import {useEffect, useState } from "react";
import Button from "@mui/material/Button";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { Box, Grid, Typography, Container } from "@mui/material";
import { useStyle } from "./Home.style";
import Image from "../../assets/img";
import classnames from "classnames";
import Logotop from '../../assets/img/logo-everial.png';
import ReactGA from "react-ga4";
import { getConfiguration, setGlobalDesign } from '../Design/Global';


export default function Home() {
  const navigate = useNavigate();
  const classes = useStyle();

  // State disign
  const [logo, setLogo] = useState(Logotop)
  // const [backgroundColor, setBackgroundColor] = useState('#fe0154')
  const [styleHomeContenaire, setStyleHomeContenaire] = useState();
  const [homeText, setHomeText] = useState('<b> Prends la pose, reçois ta photo personnalisée</b> et participe ainsi à l\'animation');
  const [styleHomeButton, setStyleHomeButton] = useState();
  const [imageContenaire, setImageContenaire] = useState();
  const [styleImageContenaire, setStyleImageContenaire] = useState();
  const [labelButtonHome, setLabelButtonHome] = useState('C\'est parti')
  const [logoFooter, setLogoFooter] = useState();
  const [styleLogoFooter, setStyleLogoFooter] = useState();
  // Fin state design

  const goToPhoto = () => {
    ReactGA.event({
      category: 'Home',
      action: 'C\'est parti'
    });
    // isMobile ? navigate("/photo") :  navigate("/select");
    navigate("/cadres");
  }

  useEffect(() => {

    console.log('je passe par ici aussi bebe');
    const fetchDataConfiguration = async () => {
      return  await getConfiguration();
    }

    fetchDataConfiguration()
    .then(configuration => {

      console.log(configuration);

      setGlobalDesign(configuration.design_json);
      
      if (configuration.design_json.home.container.style) {
        setStyleHomeContenaire(configuration.design_json.home.container.style);
      }
      
      if (configuration.design_json.home.container.text) {
        setHomeText(configuration.design_json.home.container.text);
      }
  
      if(configuration.design_json.home.container.button.style) {
        setStyleHomeButton(configuration.design_json.home.container.button.style);
      }
  
      if(configuration.design_json.home.container.button.label) {
        setLabelButtonHome(configuration.design_json.home.container.button.label);
      }
  
      if(configuration.design_json.home.container.image.url) {
        setImageContenaire(configuration.design_json.home.container.image.url);
      }
  
      if(configuration.design_json.home.container.image.style) {
        setStyleImageContenaire(configuration.design_json.home.container.image.style);
      }
  
      if(configuration.design_json.home.footer.logo.url) {
        setLogoFooter(configuration.design_json.home.footer.logo.url);
        setStyleLogoFooter(configuration.design_json.home.footer.logo.style);
      } 
    })
    .catch(console.error);
  }, []);

  return (
    <>
      <Container className="App" maxWidth="sm" style={{ position: "relative" }}>
        <Grid className={classes.gridTopImage}>
          <img src={logo} className="logoTopHome" alt="img" id="logoTop" />
        </Grid>
        
        <Box className={classes.boxContainer} style={styleHomeContenaire}>
          <Grid className={classes.gridMiddleImage}>
            {imageContenaire ? <img src={imageContenaire} alt="Img" style={styleImageContenaire} /> : '' }
          </Grid>
          <Grid>
            <Typography dangerouslySetInnerHTML={{__html: homeText}} className={classnames(classes.typography)}>
            </Typography>
          </Grid>
        </Box>
        <Grid className={classes.gridButton}>
          <Button
            onClick={() => goToPhoto()}
            variant="contained"
            size="large"
            startIcon={<RocketLaunchIcon style={{ fontSize: "35px" }} />}
            className={classes.goButton}
            style={styleHomeButton}
          >
            {labelButtonHome}
          </Button>
        </Grid>
        <Grid className={classes.gridLastImage}>
          {logoFooter ? <img src={logoFooter} style={styleLogoFooter} alt="logo" />  : ''}
        </Grid>
      </Container>
      <Container className="footterApp" maxWidth="sm" style={{ position: "relative" }}>
        <Grid className={classes.menuBottom}>
            {/* <Typography className={classes.menuTypoBottom} onClick={() => navigate("/condition-jeu")}>Voir les conditions du jeu</Typography> */}
            <Typography className={classes.menuTypoBottom} onClick={() => navigate("/mention-legale")}>Mentions légales</Typography>
        </Grid>
      </Container>
    </>
  );
}
