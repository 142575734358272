import SendIcon from '../../../assets/img/send-svgrepo-com.svg';

const Options = (props) => {

    const LinkReglement = () => {
      return (<a href='#' className='linkReglement' 
      onClick= {(e) => {
        showRelegment(e);
      }}>Voir le règlement</a>) ;
    }

    const showRelegment = (e) => {
      document.getElementById('id_pageReglement').classList.remove('hide');
  
      const url = new URL(window.location);
      url.searchParams.set('regelement', '1');
      window.history.pushState({}, '', url);
  
      return false;
    }

    return (
      <div className="learning-options-container">
        <span>{props.infoMessage.message}</span>
        {props.infoMessage.inputType === "reglement" ? <LinkReglement /> : ''}
        
        <div className="contanierCheck" id={'containerOption-' + props.infoMessage.champ_id }>
            {props.infoMessage.options.map((option) => {
                return (
                    <label className="customLabel"
                    key={option.id}
                    >
                    <input type={props.infoMessage.inputTypeOption === 'checkbox' ? 'checkbox' : 'radio'}
                        className="option-item"
                        data-champid={props.infoMessage.champ_id}
                        value={option.value}
                        name={props.infoMessage.inputTypeOption === 'checkbox' ? props.infoMessage.champ_id + '[]' : option.name}
                        data-label={option.label}
                        onChange= {(e) => {
                          props.handleOptionChange(e);
                        }}
                        /> {option.label}
                    </label>
                );
            })}
            <button id={'validateOptin-' + props.infoMessage.champ_id}
              className='btn btn-rounded btn-secondary btn-sm disableCss validateOptin'
              disabled={props.disabled}
              data-optinvalue=''
              data-messageuser=''
              data-champid={props.infoMessage.champ_id}
              data-currentordre={props.infoMessage.ordre}
              onClick = {(e) => {
                if (!e.target.classList.contains('disableCss')) {
                  props.validateOption(e);
                }
              }}
              >
              <img className='iconSend' src={SendIcon} alt='icon message' />
          </button>
        </div>
      </div>
    );
  };
  
  export default Options;