import React from 'react';
import { getConfiguration } from '../../Design/Global';

export let enCoursEnvoi = 0;
export let errorEnvoiData = 0;


export const messages = () => {
  // const messages = [
  //   {
  //     "champ_id" : null,
  //     "ordre" : 0,
  //     "message" : 'Super... ✌️, merci pour ta photo ! 🤩 Ceci est un test de message initial',
  //     "inputType" : "message",
  //   },
  //   {
  //     "champ_id" : 3498,
  //     "ordre" : 1,
  //     "message" :  "<strong>Votre email svp ?</strong> 📝",
  //     "inputType" : "email",
  //     "message_error_validation" : '⛔ Email invalide. Merci de réessayer.',
  //   },
  //   {
  //     "champ_id" : 3499,
  //     "ordre" : 2,
  //     "message" :  "Your <strong>telephone ???</strong>",
  //     "inputType" : "telephone",
  //   },
  //   {
  //     "champ_id" : 3500,
  //     "ordre" : 3,
  //     "inputType" : "options",
  //     "inputTypeOption" : 'radio',
  //     "message" : 'Souhaitez-vous reçevoir la photo par email ?',
  //     "options" : [
  //       {
  //         "id" : 1,
  //         "label" : "Oui bien sur ! 🚀",
  //         "handler" : "handleTrue",
  //         "name" : 'champ_optin_email',
  //         "value" : "oui"
  //       },
  //       {
  //         "id" : 2,
  //         "label" : "Non, je ne souhaite pas !",
  //         "handler" : "handleFalse",
  //         "name" : 'champ_optin_email',
  //         "value" : "non"
  //       }
  //     ]
  //   },
  //   {
  //     "champ_id" : 3501,
  //     "ordre" : 4,
  //     "inputType" : "options",
  //     "inputTypeOption" : 'checkbox',
  //     "message" : 'Es-tu bien d’accord que ta photo soit présente dans la galerie de l\'événement ? (usage interne) 🎉',
  //     "options" : [
  //       {
  //         "id" : 1,
  //         "label" : "Ah Oui bien sur ! 🚀",
  //         "handler" : "handleTrue",
  //         "name" : 'champ_optin_email',
  //         "value" : "oui"
  //       }
  //     ]
  //   },
  //   {
  //     "champ_id" : null,
  //     "ordre" : 5,
  //     "inputType" : "message",
  //     "message" : '<div className="learning-options-container"><p><b> Nous avons une surprise pour toi 🎁! </b> <br/><br/><span>Afin de lire toute l’actu des festivals et + encore,<b> nous t’offrons un abonnement au journal numérique Ouest-France ! ☺</b></span></p></div>',
  //     "duration" : 2000
  //   },
  //   {
  //     "champ_id" : 3502,
  //     "ordre" : 6,
  //     "inputType" : "date",
  //     "message" : "Pour cela, donne nous ta date de naissance svp.... :",
  //     "isLast" : false
  //   },
  //   {
  //     "champ_id" : null,
  //     "ordre" : 7,
  //     "inputType" : "message",
  //     "message" : "C'est noté !<br/> <b>Tu vas recevoir un email pour obtenir ton cadeau !</b> ✌🔥",
  //   },
  //   {
  //     "champ_id" : 3503,
  //     "ordre" : 8,
  //     "inputType" : "reglement",
  //     "message" : "Afin de finaliser ta participation, merci de valider le règlement ! 👍",
  //     "inputTypeOption" : 'checkbox',
  //     "options" : [
  //       {
  //         "id" : 1,
  //         "label" : "Oui, j’accepte ! 🚀",
  //         "handler" : "handleTrue",
  //         "name" : 'champ_optin_reglement',
  //         "value" : "oui"
  //       }
  //     ]
  //   },
  //   {
  //     "champ_id" : null,
  //     "ordre" : 9,
  //     "inputType" : "message",
  //     "message" : "🙏 Merci ta participation est bien prise en compte ! <b>On te donne rdv sur notre stand pour récupérer ta photo imprimée ! 👋</b>) : (<b>Nous t'envoyons immédiatement ta photo par email ! 👋</b>",
  //   },
  //   {
  //     "champ_id" : null,
  //     "ordre" : 10,
  //     "inputType" : "message_download",
  //     "message" : "N'hésite pas à <b>partager ta photo</b> sur tes réseaux sociaux favoris ! ❤️ 🔥‍",
  //   },
  //   {
  //     "champ_id" : null,
  //     "ordre" : 11,
  //     "inputType" : "message_return",
  //     "message" : "Bon festival 🎉 et <b>retrouve-nous sur le stand Ouest-France sur le festival</b> où l'on a pas fini de t'étonner ! 👍",
  //   }
    
  // ];

  const fetchDataConfiguration = async () => {
    return  await getConfiguration();
  }

  fetchDataConfiguration()
    .then(configuration => {
      console.log(configuration.chatbot_json);
      return configuration.chatbot_json;
    })
    .catch(console.error);
}

export const getMessage = (index) => {
  // const configuration = JSON.parse(localStorage.getItem("configuration"));
  let configuration;
  try {
      // a = JSON.parse(response);
      configuration = JSON.parse(localStorage.getItem("configuration"));

      if (configuration.chatbot_json) {
        // console.log(configuration.chatbot_json[0]);
        
        return configuration.chatbot_json[index];
      }
  } catch (e) {
       console.error(e); // error in the above string (in this case, yes)!
       return [];
  }
  // console.log(configuration.chatbot_json);

  return [];
}

// export const getMessage = (order) => {
//   // let allMessages = messages();

//   const fetchDataConfiguration = async () => {
//     return await getConfiguration();
//   }

//   // let allMessages = [];
//   fetchDataConfiguration()
//     .then(configuration => {
//       // console.log(configuration.chatbot_json);
//       let allMessages = configuration.chatbot_json;

//       console.log(allMessages);

//       allMessages = allMessages.filter((item, key) => {
//         return key === order;
//       });
    
//       let message = null;
//       if (Object.values(allMessages).length) {
//         message = allMessages[0];
//       }

//       console.log(message);
    
//       return message;
      
//     })
//     .catch(console.error);
// }

export const generateHtmlMessage = (message) => {
  return (
    <div dangerouslySetInnerHTML={{__html: message}} />
  );
}

export const validateEmail = (email) => {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePhone = (phone) => {
  //const regex = /^\+33|0|0033\d{9}$/;
  const regex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
  return regex.test(String(phone).toLowerCase()); //phone.match(regex);
};

export const storeDataImage = (image) => {
  var dataImg = {};
  dataImg['captured_img'] = image;
  dataImg['date_photo'] = dateNow();
  dataImg['name_photo'] = setPhotoName();
  //console.log(window.dataPhoto)
  window.dataPhoto = dataImg;
  window.dataDowload = dataImg;

  localStorage.setItem("dataPhoto", JSON.stringify(dataImg));
}

export const storeImageFusionned = (image) => {
  window.dataPhoto.fusioned_img = image;
  window.dataDowload.fusioned_img = image;
}

export const resetDataImage = (image) => {
  localStorage.setItem("dataImg", JSON.stringify([]));
}

export const dateNow = () => {
  const today = new Date();
  const date = today.getFullYear()+'-'+
                addZero((today.getMonth()+1), 2)+'-'+
                addZero(today.getDate(), 2)+' '+
                addZero(today.getHours(), 2)+':'+
                addZero(today.getMinutes(), 2)+':'+
                addZero(today.getSeconds(), 2);
  
  return date;
}

export const setPhotoName = () => {
  const today = new Date();
  const photoName = today.getFullYear()+'_'+
                addZero((today.getMonth()+1), 2)+'_'+
                addZero(today.getDate(), 2)+'_'+
                addZero(today.getHours(), 2)+'_'+
                addZero(today.getMinutes(), 2)+'_'+
                addZero(today.getSeconds(), 2)+'_'+
                addZero(today.getMilliseconds(), 5);

  return photoName;
}

export const addZero = (x,n) => {
  while (x.toString().length < n) {
    x = "0" + x;
  }
  return x;
}

export const saveResponse = (idChamp, response, type) => {
  var res = {id : idChamp, value : response, type : type};
  console.log(res);
  var dataResponse = window.dataChamps;
  dataResponse[idChamp] = res;
  
  var champs = dataResponse.filter((item) => {
    return item !== null;
  });
  console.log(champs)
  champs = champs.map((item, key) => {
      delete item['type'];
      item.value = (item.value.toLowerCase() === "non" ) ? '0' : item.value;
      item.value = (item.value.toLowerCase() === "oui" ) ? '1' : item.value;

      return item;
  });
  window.dataChamps = champs;
}

const afterEnvoi = () => {
  enCoursEnvoi--;
  if (document.getElementById('textLoaderEvnoi')) {
    document.getElementById('textLoaderEvnoi').innerHTML = 'Envoi terminé et effectué avec succès ! 🤩';
  }
  if (document.getElementById('containerLoaderGif')) {
    document.getElementById("containerLoaderGif").classList.add('hide');
  }
  if (document.getElementById('chekImageLoader')) {
    document.getElementById("chekImageLoader").classList.remove('hide');
  }
  console.log(' ==> errorEnvoiData' + errorEnvoiData);

  if (errorEnvoiData > 0) {
    console.log('envoi data maintenant');
    uploadData();
  }
}

export const uploadPhoto = (options) => {
  const upload = async () => {

      var dataPhoto = window.dataPhoto;
      var data = {
          borne_id: "VB",
          code_logiciel: process.env.REACT_APP_CODE_EVENT,
          date_photo: dataPhoto['date_photo'], 
          name_photo: dataPhoto['name_photo'] + "A.jpg", 
          save_date: dataPhoto['date_photo'],
          type: "photo-cropped",
          cadre_name : options.cadreName ? options.cadreName : 'cadre.png',
          cadre_id : window.cadre_id,
          hole : window.hole,
      };

      const urlUpload = process.env.REACT_APP_BASE_URL + "fr/NextCloudHooks/uploadPhotoVB/";

      enCoursEnvoi++;
      await fetch(urlUpload, {
          method: "POST",
          headers: {
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data : data,
            customized : dataPhoto['captured_img']
          }),
      })
      .then(res => consume(res.body))
      .then(response => response.json())
      .then(res => {
        console.log(res)
        afterEnvoi();
      })
      .catch(err => {
        console.log(err);
        afterEnvoi();
      });
  }
  upload();
}

export const donwloadImage = () => {
  var dataPhoto = window.dataDowload.fusioned_img;
  // console.log(dataPhoto);
  const a = document.createElement('a')
  a.target = '_blank';
  a.download = 'photo-personnalisee.jpg'
  a.href = dataPhoto;
  a.click();
}

const consume = (stream, total = 0) => {
  console.log(stream);
  while (stream.state === "readable") {
    var data = stream.read()
    total += data.byteLength;
    console.log("received " + data.byteLength + " bytes (" + total + " bytes in total).")
  }
  if (stream.state === "waiting") {
    stream.ready.then(() => consume(stream, total))
  }
  return stream.closed
}

export const uploadData = () => {
  const upload = async () => {

    var dataPhoto = JSON.parse(localStorage.getItem("dataPhoto")); //window.dataPhoto;
    console.log(dataPhoto);
    if (dataPhoto) {
      var data = {
          borne_id: "VB",
          code_logiciel: process.env.REACT_APP_CODE_EVENT,
          date_photo: dataPhoto['date_photo'], 
          name_photo: dataPhoto['name_photo'] + ".jpg", 
          save_date: dataPhoto['date_photo'],
          type: "photo-customized",
          champs : window.dataChamps, //JSON.parse(localStorage.getItem("user_responses"))
      };
      console.log(data);
      const urlUpload = process.env.REACT_APP_BASE_URL + "/fr/NextCloudHooks/uploadDataVB"; 

      //enCoursEnvoi++;
      await fetch(urlUpload, {
          method: "POST",
          headers: {
              'Accept' : 'application/json',
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data : data
          }),
      })
      .then(res => consume(res.body))
      .then(response => response.json())
      .then(res => {
        //enCoursEnvoi--;
        console.log(res);
        if (res.success) {          
          console.log(res)
          localStorage.removeItem("dataPhoto")
          window.dataPhoto = [];
          window.dataChamps = [];
          errorEnvoiData = 0;
          return true;
        } else {
            //return false;
            errorEnvoiData++;
        }
      })
      .catch(err => {
        console.log(err);
        errorEnvoiData++;
      });
    }
  }
  return upload();
}