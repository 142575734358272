import { saveResponse, uploadData, generateHtmlMessage, enCoursEnvoi, getMessage} from '../Utilities';
import DownloadIcon from '../../../assets/img/download.svg';
import HomeIcon from '../../../assets/img/home.svg';
import Done from '../../../assets/img/done.svg';
import Options from '../widgets/Options';
import WidgetDate from '../widgets/WidgetDate';

class ActionProvider {

   constructor(
    createChatBotMessage,
    setStateFunc,
    createClientMessage,
    stateRef,
    createCustomMessage,
    ...rest
  ) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
    this.createClientMessage = createClientMessage;
    this.stateRef = stateRef;
    this.createCustomMessage = createCustomMessage;
  }

  updateChatbotState = (message) => {
    this.setState(prevState => ({
      ...prevState, messages: [...prevState.messages, message]
    }))
  }

  handleNextMessage = (ordre) => {
    const nextMessage  = getMessage(ordre);
    if (nextMessage) {
      if (nextMessage.inputType === "options" || nextMessage.inputType === "reglement") {
        const message = this.gerenerateOptionMessage(nextMessage);

        const botMessage = this.createChatBotMessage(message,
          { 
            key : nextMessage.ordre,
            required : false,
            withAvatar: true,
            infoMessage : nextMessage
          });
        this.updateChatbotState(botMessage);
        // this.disableForm();
      } else if (nextMessage.inputType === "date") {
        const message = this.generateDateMessage(nextMessage);

        const botMessage = this.createChatBotMessage(message,
          { 
            key : nextMessage.ordre,
            required : false,
            withAvatar: true,
            infoMessage : nextMessage
          });
        this.updateChatbotState(botMessage);
      } else {
        let message = generateHtmlMessage(nextMessage.message);
        if (nextMessage.inputType === "message_return") {
          message = this.genarateMessageReturn(nextMessage);
        } else if (nextMessage.inputType === "message_download") {
          message = this.generateMessageDownload(nextMessage);
          // On envoi maintenat les données 
          uploadData();
        }

        const botMessage = this.createChatBotMessage(message,
          { 
            key : nextMessage.ordre,
            required : false,
            withAvatar: true,
            infoMessage : nextMessage
          });
        this.updateChatbotState(botMessage);

        if (nextMessage.inputType === "message" || nextMessage.inputType === "message_return" ||  nextMessage.inputType === "message_download") {
          // console.log('je passe ici gona aa');
          const messageSuite  = getMessage(parseInt(nextMessage.ordre) + 1);
          if (messageSuite) {
            setTimeout(() => {
              this.handleNextMessage(parseInt(messageSuite.ordre));
            }, 2000)
          }
        }
      }
    } else {
      // On envoi les données quand on a plus de message
      uploadData();
    }
  }

  handleMessageError = (message) => {
    const errorMessage = this.createChatBotMessage(message.message_error_validation ? message.message_error_validation : `⛔ Format invalide. Merci de réessayer.`);
    this.updateChatbotState(errorMessage);
    this.desactiveForm();
    setTimeout( () => {
      this.reactiveForm();
    }, 1000);
  } 

  disableForm = () => {
    const input = document.getElementsByClassName('react-chatbot-kit-chat-input')[0];
    const form = document.getElementsByClassName('react-chatbot-kit-chat-input-form')[0];
    const btnSubmit = document.getElementsByClassName('react-chatbot-kit-chat-btn-send')[0];

    form.classList.add('disable-message');
    input.disabled = true;
    btnSubmit.disabled = true;
    const inputBloc = document.getElementsByClassName('react-chatbot-kit-chat-input-container')[0];
    inputBloc.classList.add('hide');
  }

  desactiveForm = () => {
    const input = document.getElementsByClassName('react-chatbot-kit-chat-input')[0];
    const form = document.getElementsByClassName('react-chatbot-kit-chat-input-form')[0];
    const btnSubmit = document.getElementsByClassName('react-chatbot-kit-chat-btn-send')[0];

    form.classList.add('invalide-message');
    input.disabled = true;
    btnSubmit.disabled = true;
    input.placeholder = "Ce format n'est pas valide.";
  }

  reactiveForm = () => {
    const input = document.getElementsByClassName('react-chatbot-kit-chat-input')[0];
    const form = document.getElementsByClassName('react-chatbot-kit-chat-input-form')[0];
    const btnSubmit = document.getElementsByClassName('react-chatbot-kit-chat-btn-send')[0];

    input.placeholder = "Ta réponse ...";
    input.disabled = false;
    btnSubmit.disabled = false;
    form.classList.remove('invalide-message');
  }

  handlerLoaderPhoto = () => {
     return (
      <div className="loaderMessagePhoto" id="lodaerMessagebloc">
        <div id="containerLoaderGif" className="loadingio-spinner-rolling-tg9xsw2f5t">
          <div className="ldio-z4j07sq3ty">
            <div></div>
          </div>
        </div>
        <img src={Done} className='chekImage hide' id="chekImageLoader" alt='loader' />
        <div id="textLoaderEvnoi" className='textLoader'>⚠️  <b>L’envoi des informations est en cours, merci de patienter quelques instants ! 💪</b></div>
      </div>
    )
  }

  hangleRemerciement = () => {
    var optionsRemerciement = {
      delay : 0,
      withAvatar : true,
    };
    if (enCoursEnvoi >0) {
      console.log(' Ajax en cours envoi photo');
      const botMessageLoaderPhoto = this.createChatBotMessage(this.messageLoaderPhoto());
      this.updateChatbotState(botMessageLoaderPhoto);
      optionsRemerciement.delay = 2000;
    } else {
      console.log('photo envoyé');
    }

    uploadData();

    const botMessage = this.createChatBotMessage(this.messageRemerciement(), optionsRemerciement);
    this.updateChatbotState(botMessage);
 
    this.disableForm();
    const inputBloc = document.getElementsByClassName('react-chatbot-kit-chat-input-container')[0];
    inputBloc.classList.add('hide');
    document.querySelector(".react-chatbot-kit-chat-message-container").style = `height: ${document.documentElement.clientHeight}px`;
    // window.scrollTo(0, document.querySelector(".react-chatbot-kit-chat-message-container").scrollHeight);

    const botMessageTelechargement = this.createChatBotMessage(this.messageTelechargement(), {
      delay: 3700,
      withAvatar: true,
    });
    this.updateChatbotState(botMessageTelechargement);

    const botMessageFinal = this.createChatBotMessage(this.messageFinal(), {
      delay: 5000,
      withAvatar: true,
    });
    this.updateChatbotState(botMessageFinal);

    //setTimeout(uploadData(), 5000);
  }

  gerenerateOptionMessage = (infoMessage) => {
    return <Options
              validateOption={this.validateOption}
              handleOptionChange={this.handleChangeOption}
              infoMessage={infoMessage}
               />;
  }

  generateDateMessage = (infoMessage) => {
    return <WidgetDate 
              validateDate={this.validateDate}
              infoMessage={infoMessage}
              />
  }

  validateDate = ({target}) => {
    // Update user message
    const labelSelected = target.dataset.messageuser;
    const message = this.createClientMessage(labelSelected);
    this.updateChatbotState(message);

    // disable button
    target.classList.add('disableCss');
    target.setAttribute('disabled', 'disabled');
    target.disabled = true;

    //disable change form select
    const champId = target.dataset.champid;
    const dayDate = document.getElementById('id_dayDate-'+ champId);
    dayDate.classList.add('disableCss');
    dayDate.disabled = true;
    dayDate.setAttribute('disabled', 'disabled');

    const monthDate = document.getElementById('id_monthDate-'+ champId);
    monthDate.classList.add('disableCss');
    monthDate.disabled = true;
    monthDate.setAttribute('disabled', 'disabled');

    const yearDate = document.getElementById('id_yearDate-'+ champId);
    yearDate.classList.add('disableCss');
    yearDate.disabled = true;
    yearDate.setAttribute('disabled', 'disabled');

    //Save reponse
    const value = target.dataset.datevalue;
    saveResponse(champId, value , 'date');

    //Show next message
    const currentOrdre = parseInt(target.dataset.currentordre);
    this.handleNextMessage(currentOrdre+1);
  }

  handleChangeOption = ({target}) => {
    const champId = target.dataset.champid;

    const parent = document.querySelector('#containerOption-' + champId);
    const customLabels = parent.querySelectorAll('.customLabel');
    for (const customLabel of customLabels) {
      customLabel.classList.remove('selected');
    }
    target.parentElement.classList.add('selected');

    const validationButton = document.querySelector('#validateOptin-' + champId);
    validationButton.disabled = false;
    validationButton.removeAttribute('disabled');
    validationButton.classList.remove('disableCss');
    validationButton.setAttribute('data-messageuser', target.dataset.label);
    validationButton.setAttribute('data-optinvalue', target.value);
  }

  validateOption = ({target}) => {
    const labelSelected = target.dataset.messageuser;

    const message = this.createClientMessage(labelSelected);
    this.updateChatbotState(message);

    target.setAttribute('disabled', 'disabled');
    target.disabled = true;
    target.classList.add('disableCss')

    const champId = target.dataset.champid;
    const parent = document.querySelector('#containerOption-' + champId);
    const inputs = parent.querySelectorAll('.option-item');
    for (const input of inputs) {
      input.disabled = true;
    }

    //Save response
    const optinValue = target.dataset.optinvalue;
    saveResponse(champId, optinValue , 'optin');

    const currentOrdre = parseInt(target.dataset.currentordre);
    this.handleNextMessage(currentOrdre+1);
  }

  generateMessageDownload = (infoMessage) => {
    return (
      <>
        <span>{generateHtmlMessage(infoMessage.message)}</span>
        <button 
          className='btn btn-telecharment btn-sm btn-rounded'
          onClick = {(e) => {
            this.donwloadImage();
          }}
          >
          <img className='iconDownload' src={DownloadIcon}  alt='download'/> Télécharger
        </button>
      </>
    )
  }

  genarateMessageReturn = (infoMessage) => {
    return (
      <>
        <span>{generateHtmlMessage(infoMessage.message)}</span>
        <a 
          className='btn btn-retour btn-sm btn-rounded'
          href='/'
          >
          <img className='iconBack' src={HomeIcon} alt='Home' /> Retour à l’accueil
        </a>
      </>
    )
  }

  donwloadImage = () => {
    var dataPhoto = window.dataDowload.fusioned_img;
    // console.log(dataPhoto);
    const a = document.createElement('a')
    a.target = '_blank';
    a.download = 'photo-'+ process.env.REACT_APP_CODE_EVENT +'.jpg';
    a.href = dataPhoto;
    a.click();
  }

}

export default ActionProvider;