import React, { useEffect, useState } from "react";
import Chatbot from "react-chatbot-kit";
import "/node_modules/bootstrap/dist/css/bootstrap.min.css";
import "react-chatbot-kit/build/main.css";
import "./styles/chatbot.css";

import {Container} from "@mui/material";

import Config from "./App/Config";
import MessageParser from "./App/MessageParser";
import ActionProvider from "./App/ActionProvider";
// import validateInput from "./App/Validator";
import Cgj from "../Contenu/Cgj";
//import axios from 'axios';
import  { useNavigate } from 'react-router-dom';
//import { Modal, Button } from 'react-bootstrap';
import { getConfiguration, setGlobalDesign } from '../Design/Global';


function App() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
      setShow(true);
  }

  /*const saveMessages = (messages, HTMLString) => {
    console.log(messages)
    localStorage.setItem('chat_messages', JSON.stringify(messages));
  };

  const loadMessages = () => {
    const messages = JSON.parse(localStorage.getItem('user_responses'));
    console.log(messages)
    return messages;
  };*/
  
  const navigate = useNavigate();
  useEffect(() => {
    const fetchDataConfiguration = async () => {
        return  await getConfiguration();
    }

    fetchDataConfiguration()
      .then(configuration => {
      setGlobalDesign(configuration.design_json);
    })
    .catch(console.error);

    if (JSON.parse(localStorage.getItem("dataPhoto")) === null) {
      navigate("/");
    }

  }, []);

  useEffect(() => {
    // document.querySelector(".MuiContainer-maxWidthSm").classList.add("react-chatbox-container");
    document.querySelector(".react-chatbot-kit-chat-message-container").style = `height: ${document.documentElement.clientHeight}px`;
    document.getElementById('thTchatContaier').style = `height: ${document.documentElement.clientHeight}px`;
    document.querySelector(".react-chatbot-kit-chat-input").setAttribute('autocomplete', 'off');

  }, [])

  return (
    <>
      {
        <Container id="thTchatContaier" className="containerChat" maxWidth="sm" style={{ position: "relative" }}>
        <div className="containerChatBox">
          <Chatbot
            config={Config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
            headerText="Virtual booth chat"
            placeholderText="Ta réponse ..."
            // validator={validateInput}
            scrollIntoView="bottom"
            //messageHistory={loadMessages()}
            //saveMessages={saveMessages}
            //runInitialMessagesWithHistory
            //disableScrollToBottom
          />
          
          <div className="pageReglement hide" id="id_pageReglement">
              <Cgj isPopup={true}/>
          </div>

          <div className="hide id_modalEnvoi">
              <div className="fade modal-backdrop show"></div>
              <div role="dialog"  aria-modal="true" className="fade modal show" tabIndex="-1" style={{display: 'block' }}>
                <div id="id_modalEnvoi" className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title h4">Message</div>
                            <button type="button" className="btn-close" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">Votre photo est encours d'envoi, veuillez ne pas quitter s'il vous plait !</div>
                        <div className="modal-footer"><button type="button" className="btn btn-secondary">Close</button></div>
                    </div>
                </div>
              </div>
          </div>

        </div>
        </Container>
      }
    </>
  );
}

export default App;
