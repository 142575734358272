import SendIcon from '../../../assets/img/send-svgrepo-com.svg';
import DateInputSelect from "../Template/DateInputSelect";

const WidgetDate  = (props) => {
    const dataYear = ['AAAA',
        /*2023,2022 ,2021,2020 ,2019 ,2018 ,,2017 ,2016 ,2015 ,2014 ,2013 ,2012,*/2011,2010 ,2009,2008,2007,2006 ,2005 ,2004,2003,2002 ,2001 ,2000 ,1999,1998,1997,
        1996,1995 ,1994,1993,1992,1991 ,1990 ,1989,1988 ,1987 ,1986 ,1985 ,1984,1983,1982 ,1981,1980,1979,1978,1977,1976,1975,1974,1973,1972,1971,1970,1969,1968,
        1967,1966,1965,1964,1963,1962,1961,1960,1959,1958,1957,1956,1955,1954,1953,1952,1951,1950
    ];
    const dataMonth = ['MM','01','02','03','04','05','06','07','08','09',10,11,12];
    const dataDay = ['JJ','01','02','03','04','05','06','07','08','09',10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];
    const today = new Date();

    let valDay = 'JJ';
    let valMonth = 'MM';
    let valYear = 'AAAA';

    const handleInputDay = (value, champId) => {
      valDay = value
      checkInput(champId)
    };

    const handleInputMonth = (value, champId) => {
      valMonth = value
      checkInput(champId)
    };

    const handleInputYear = (value, champId) => {
      valYear = value
      checkInput(champId)
    };

    const addZero = (x,n) => {
      while (x.toString().length < n) {
        x = "0" + x;
      }
      return x;
    }

    const checkInput = (champId) => {      
      if (valDay != 'JJ' && valMonth != 'MM' && valYear != 'AAAA') {
        document.getElementById('validateDate-' + champId).classList.remove('disableCss');
      } else {
        document.getElementById('validateDate-' + champId).classList.add('disableCss');
      }
      userMessage(champId);
    }

    const userMessage = (champId) => {
      const day = document.getElementById('id_dayDate-'+ champId).value
      const month = document.getElementById('id_monthDate-'+ champId).value
      const year = document.getElementById('id_yearDate-'+ champId).value
      const previewValue = addZero(day, 2) + '/' + addZero(month, 2) + '/' + year
      const dateValue = year + '-' + addZero(month, 2) + '-' + addZero(day, 2)

      document.getElementById('validateDate-' + champId).setAttribute('data-messageuser', previewValue);
      document.getElementById('validateDate-' + champId).setAttribute('data-datevalue', dateValue);
    }

    return  (<div className="learning-options-container">
                <div>{props.infoMessage.message}</div>
                {/* <span>Pour cela,<strong> donne nous ta date de naissance :</strong></span> */}
                <div className='chb-grp-date'>
                  <DateInputSelect 
                    data={dataDay} 
                    dataId={'id_dayDate-' + props.infoMessage.champ_id}
                    hasDivider={true} 
                    defaultValue={today.getDate()}
                    onInputChange={(value) => {
                      handleInputDay(value, props.infoMessage.champ_id)
                    }}
                  />   
                  <DateInputSelect 
                    data={dataMonth} 
                    dataId={'id_monthDate-'+props.infoMessage.champ_id}
                    hasDivider={true} 
                    defaultValue={today.getMonth()+1} 
                    onInputChange={(value) => {
                      handleInputMonth(value, props.infoMessage.champ_id)
                    }}
                  />    
                  <DateInputSelect 
                    data={dataYear} 
                    dataId={'id_yearDate-' +props.infoMessage.champ_id}
                    hasDivider={false} 
                    defaultValue={today.getFullYear()}
                    onInputChange={(value) => {
                      handleInputYear(value, props.infoMessage.champ_id)
                    }}
                  />  
                </div> 
                <button
                    //id='id_validateDate'
                    id={'validateDate-' + props.infoMessage.champ_id}
                    className='btn btn-rounded btn-primary btn-sm m-t-20 disableCss validateBtn'
                    data-messageuser=''
                    data-datevalue=''
                    data-champid={props.infoMessage.champ_id}
                    data-currentordre={props.infoMessage.ordre}
                    onClick = {(e) => {
                        if (!e.target.classList.contains('disableCss')) {
                          props.validateDate(e);
                        }
                    }}
                    
                  >
                  <img className='iconSend' src={SendIcon} alt='Envoyer' /> Envoyer
                </button>
            </div>);
  }
  
export default WidgetDate;