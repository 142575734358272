import NativeSelect from '@mui/material/NativeSelect';

const DateInputSelect = ({data, dataId, hasDivider, defaultValue, onInputChange} ) => {
    
    const options = data.map((option, i) => (
        <option value={option} key={i}>{option}</option>
    ));

    const handleInput = (event) => {
        const value = event.target.value;
        onInputChange(value);
    };

    return (
        <div>
            {                
                <div className='chb-wrap-date m-t-5'>
                    <NativeSelect
                        // defaultValue={defaultValue}
                        inputProps={{
                            id: dataId,
                            className: 'chb-select-date'
                        }}
                        onChange={handleInput}
                    >
                        {options}
                    </NativeSelect> 
                    {hasDivider && <span className='chb-divider-date'>/</span> }                    
                </div>             
            }
        </div>
    )
}

export default DateInputSelect
