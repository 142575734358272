
import { Box, Grid, Typography, Container } from "@mui/material";
import { useStyle } from "./Selections.style";
import Logotop from '../../assets/img/logo-everial.png';
import classnames from "classnames";
import { useState, useEffect } from "react";
import Photos from './Photos';
import { getConfiguration, setGlobalDesign } from '../Design/Global';

export default function Cadres() {
    const classes = useStyle();

    const [errorWs, setErrorWs] = useState(null);
    const [isLoadedWs, setIsLoadedWs] = useState(false);
    const [cadres, setCadres] = useState([]);
    const [selectedCadre, setSelectedCadre] = useState();

    const Chargement = () => {
        return (<div className="chargementCadre">Chargement...</div>);
    }

    const ListCadres = (props) => {
        return (props.listeCadres.map(cadre => (
            <Grid
                key={cadre.id}
                onClick={(e) => goToPrisePhoto(cadre)}
                className={classes.gridButtonSelections}>
                    <img src={cadre.url} className="cadrePhoto" alt='cadre' />
            </Grid>
        )));
    }

    const goToPrisePhoto = (cadre) => {
        setSelectedCadre(cadre);
        saveCadreSelect(cadre);
    };

    const saveCadreSelect = (cadre) => {
        window.cadre_id = cadre.id;
        window.hole = cadre.hole;
    }

    useEffect(() => {
        const fetchDataConfiguration = async () => {
            return  await getConfiguration();
        }
      
        fetchDataConfiguration()
        .then(configuration => {
            setGlobalDesign(configuration.design_json);
        })
        .catch(console.error);

        
        const headers = { 'Authorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjExNzcsImV4cCI6MTY4NTc5MjM0MSwicm9sZSI6MX0.tIXmLTQXGJh8y31UqYoCoWqqfJ6F4iG45g3iYyszVYk' };
        fetch(process.env.REACT_APP_BASE_URL + "api/cadres/liste/"+ localStorage.getItem("code_logiciel") + ".json", {headers})
          .then(res => res.json())
          .then(
            (result) => {
                setIsLoadedWs(true);
                setCadres(result.cadres);
            },
            // Remarque : il faut gérer les erreurs ici plutôt que dans
            // un bloc catch() afin que nous n’avalions pas les exceptions
            // dues à de véritables bugs dans les composants.
            (error) => {
                setIsLoadedWs(true);
                setErrorWs(error);
            }
          )
    }, [])

    return (
        <>
        {!selectedCadre ? (
          <Container className="App" maxWidth="sm" style={{ position: "relative" }}>
                <Grid className={classes.gridTopImage}>
                <img src={Logotop} className="logoTopHome" alt='logo' id="logoTop" />
                </Grid>
                <Box className={classes.boxContainerSelections}>
                    <Grid>
                        <Typography className={classnames(classes.typography)}>
                            <b>Veuillez séléctionner un cadre.</b>
                        </Typography>
                    </Grid>

                    {!isLoadedWs ? (
                        <Chargement />
                    ) : (
                        <ListCadres listeCadres={cadres} />
                    )}
                </Box>          
          </Container>) : 
          (
            <Photos cadre={selectedCadre} />
          )}
        </>
        );
}
