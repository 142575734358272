
export const getDesignStatic = () => {

    console.log(window.location.hostname);

    const design = {
      "logo" : {
        "url" : "https://booth.selfizee.fr/img/logo-noir.png",
        "css" : {
          "margin" : "auto",
        }
      },
      "background" : {
        "color" : "green",
        "image" : ""
      },
      "home" : {
        "container" : {
          "style" : {
            "backgroundColor" : "lightgray",
            "border" : "none",
            "borderRadius" : "500px",
            "width":"500px",
            "heigth" : "500px",
          },
          "text" : "Prends ça !",
          "image" : {
            "url" : "https://www.selfizee.fr/wp-content/themes/selfizee/images/image-01.png.webp",
            "style" : {
              "height" : "300px",
              "width" : "auto",
              "margin" : "auto",
              "textAlign": "center",
              "display":"block",
              "paddingTop" : "30px",
            }
          },
          "button" : {
            "style" : {
              "color" : "white",
              "backgroundColor" : "#fe0154",
              "borderRadius" : "0px !important",
              "border" : "4px solid #f5f5f5",
              "padding" : "25px 25px",
            },
            "label" : "Alefa",
          }
        },
        "footer" : {
          "logo" : {
            "url" : "https://s.yimg.com/rz/p/yahoo_homepage_en-US_s_f_p_bestfit_homepage_2x.png",
            "style" : {
              "display" : "block",
              "margin" : "auto",
              "width" : "120px",
            }
          }
        }
      }
    };
  
    return design;
}

export async function postDomaine(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    // mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "error", // manual, *follow, error
    // referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

export async function getConfiguration() {

  let defaultConfiguration =  localStorage.getItem("configuration");

  defaultConfiguration = false;

  if (defaultConfiguration) {
    let conf;
    try {
      conf = JSON.parse(defaultConfiguration);
    } catch (e) {
        return []; // error in the above string (in this case, yes)!
    }

    return conf;
  } else {
    // let config  = {};
    await postDomaine(process.env.REACT_APP_BASE_URL + "api/VbConfigurations/getConfiguration.json", { domaine : window.location.hostname })
    .then((data) => {
      // config = data.configuration;
      localStorage.setItem("configuration", JSON.stringify(data.configuration));
      localStorage.setItem('code_logiciel', data.configuration.evenement.code_logiciel);
      localStorage.setItem('condition_utilisation', data.configuration.condition_utilisation);
    });
  }

  return JSON.parse(localStorage.getItem("configuration"));
}; 

export const setGlobalDesign = (design) => {

    // console.log(design);

    document.body.style.backgroundColor = design.background.color ? design.background.color : '#fe0154';

    if (design.logo.url && document.getElementById('logoTop')) {
        document.getElementById('logoTop').setAttribute('src', design.logo.url);
    }
}

