import mergeImages from "merge-images";
import { useState, useEffect } from "react";
import { Container, Box, Grid, Typography, Button } from "@mui/material";
import SettingsBackupRestoreOutlinedIcon from "@mui/icons-material/SettingsBackupRestoreOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { useStyle } from "./Photos.style";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import { storeImageFusionned, uploadPhoto } from '../Chatbot/Utilities';
import ReactGA from "react-ga4";
import {isMobile} from 'react-device-detect';


export default function Fusions(props) {
  const [fusionedImg, setFusionedImg] = useState(null);
  const classes = useStyle();
  const navigate = useNavigate();
  const setFusionnedImage = (src) => {
    setFusionedImg(src);
    props.updateLoader(false);
    storeImageFusionned(src);
  };
  useEffect(() => {
    var i = new Image();

    i.src = props.imgCapture;

    i.onload = function () {

      mergeImages(
        [
          {
            src: props.imgCapture,
            x: props.cadre.hole.x,
            y: props.cadre.hole.y,
          },
          {
            src : props.cadre.cadre,
            x: 0,
            y: 0,
          },
        ],
        {
          format: "image/jpeg",
          //quality : 0.5,
        }
      )
      .then((src) => setFusionnedImage(src))
      .catch((err) => console.log(err));
    };
  }, []);

 

  const retour = () => {
    props.updateCapturedImage(null, null);
    ReactGA.event({
      category: 'Photo',
      action: 'Retout sur prise  photo'
    });
  };

  const validate = () => {
    uploadPhoto({cadreName : props.cadreName});
    const configuration = JSON.parse(localStorage.getItem("configuration"));
    if (configuration.design_json.general.is_chatbot) {
        navigate("/chatbot");
    } else {
      navigate("/form");
    }
    
    ReactGA.event({
      category: 'Photo',
      action: 'Valide la photo'
    });
  }

  const handleResize = () => {
    // const fusionedImg = document.getElementById("fusionedImg");
    // if (fusionedImg) {
    //   if (isMobile) {
    //     fusionedImg.style = `width: ${
    //       document.documentElement.clientWidth - 25
    //     }px; height:auto;`;
    //   } else {
    //     fusionedImg.style = `height: ${
    //       document.documentElement.clientHeight - 220
    //     }px`;
    //   }
    // }
  }

  useEffect(() => {
    setTimeout(() => {
      handleResize();
    }, 100);
    window.addEventListener("resize", handleResize, false);
  }, []);

  return (
    <Container className="App" maxWidth="sm" style={{ position: "relative" }}>
      {fusionedImg && (
        <>
          <Grid
            className="blocVisualiationPhoto"
            style={{ textAlign: "center" }}
          >
            <img
              src={fusionedImg}
              id="fusionedImg"
              className={classes.fusionedImg}
              alt="The Fusioned"
            />
          </Grid>
          <Grid>
            <Typography className={classnames("photoVousPlait")}>
              La photo te plait ?
            </Typography>
          </Grid>
          <Grid className={classes.buttonContainerFusions}>
            <Box className="container2btn">
              <Button
                className={classnames(
                  classes.buttonFusions,
                  classes.buttonSecondaryFusions
                )}
                variant="contained"
                onClick={retour}
                size="large"
              >
                <SettingsBackupRestoreOutlinedIcon
                  style={{ fontSize: "40px" }}
                />
              </Button>
              <Button
                className={classes.buttonFusions}
                variant="contained"
                size="large"
                onClick={validate}
              >
                <CheckCircleOutlinedIcon style={{ fontSize: "40px" }} />
              </Button>
            </Box>
          </Grid>
        </>
      )}
    </Container>
  );
}
