import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Typography, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useStyle } from "./Contenu.style";
import classNames from "classnames";

export default  function Mention() {
  const navigate = useNavigate();
  const classes = useStyle();
  return (
    <Container className="App" maxWidth="sm" style={{ position: "relative", color: "white" }}>
    <Grid className={classes.containerTypo}>
      <Grid className={classNames(classes.containerTypo, classes.header)}>
        <Button
          onClick={() => navigate("/")}
          variant="contained"
          size="small"
          className={classes.closeButton}
        >
          <CloseIcon />
        </Button>
      </Grid>
      <Grid className={classes.containerTypo}>
      <div>
        <p style={{ fontWeight: 400, textAlign: "center" }}>
          <strong>Mentions légales </strong>
        </p>
        <p style={{ fontWeight: 400, textAlign: "center" }}>
          <strong>&nbsp;</strong>
        </p>
        <p style={{ fontWeight: 400, textAlign: "center" }}>
          <strong>
            Responsable <br />
            <br />
          </strong>
        </p>
        <p style={{ fontWeight: 400, textAlign: "center" }}>
          Everial
          <br />
          1691 Avenue de l’Hippodrome
          <br />
          69140 Rillieux-la-Pape
        </p>
        <p style={{ fontWeight: 400, textAlign: "center" }}>
          <strong>&nbsp;</strong>
        </p>
        <p style={{ fontWeight: 400, textAlign: "center" }}>
          <strong>Réalisation</strong>
        </p>
        <p style={{ fontWeight: 400, textAlign: "center" }}>&nbsp;</p>
        <p style={{ fontWeight: 400, textAlign: "center" }}>
          <strong>
            Selfizee <br />
            Konitys SAS&nbsp;
          </strong>
          <br />
          <em>2 Place Konrad Adenauer</em>
          <br />
          <em>22 190 Plérin&nbsp;</em>
        </p>
        <p style={{ fontWeight: 400, textAlign: "center" }}>
          <a href="http://www.selfizee.fr/">www.selfizee.fr</a>
        </p>
        <p style={{ fontWeight: 400 }}>
          L’utilisateur est informé que, conformément à l’article 27 de la loi
          informatique, fichiers et libertés du 6 janvier 1978, les informations
          communiquées par l’utilisateur du fait des formulaires présents sur le site,
          sont nécessaires pour répondre à sa demande et sont exclusivement destinées
          à la société Everial.
        </p>
        <p style={{ fontWeight: 400 }}>
          L’utilisateur est informé qu’il dispose d’un droit d’accès et de
          rectification portant sur les données le concernant en écrivant à l’adresse
          suivante du siège social.
        </p>
        <p style={{ fontWeight: 400 }}>
          Les utilisateurs du site Web everial.selfizee.fr sont tenus de
          respecter les dispositions de la loi relative à l’informatique, aux fichiers
          et aux libertés, dont la violation est passible de sanctions pénales.
        </p>
        <p style={{ fontWeight: 400 }}>
          Ils doivent notamment s’abstenir, s’agissant des informations nominatives
          auxquelles ils accèdent, de toute collecte, de toute utilisation détournée
          et, d’une manière générale, de tout acte susceptible de porter atteinte à la
          société Everial à la vie privée ou à la réputation des personnes.
        </p>
        <p style={{ fontWeight: 400 }}>
          La structure générale, ainsi que les textes, images animées ou non,
          savoir-faire et tous les autres éléments composant le site sont la propriété
          exclusive de la société Everial.
        </p>
        <p style={{ fontWeight: 400 }}>
          Toute représentation totale ou partielle de ce site par quelque société que
          ce soit, sans l’autorisation expresse de la
          société&nbsp;Everial&nbsp;est interdite et constituerait une
          contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la
          propriété intellectuelle.
        </p>
        <p style={{ fontWeight: 400 }}>
          Toute reproduction totale ou partielle des marques ou logos, effectuée à
          partir des éléments du site sans l’autorisation expresse du ou des
          détenteurs des droits s’y rapportant est donc prohibée, au sens de l’article
          L.713-2 du Code de la propriété intellectuelle.
        </p>
        <p style={{ fontWeight: 400 }}>
          Les liens hypertextes mis en place sur le site peuvent conduire
          l’utilisateur sur divers sites. Il est précisé que la
          société&nbsp;Everial n’a pas vérifié tous les sites éventuellement
          reliés au sien, leur contenu et les informations qui y sont présentes, et
          invitent l’utilisateur, sous sa responsabilité, à valider la conformité,
          l’exactitude, la complétude et/ou la licéité des informations et/ou contenus
          auxquels ils ont accès sur ces sites. La société Everial décline par
          conséquent toute responsabilité sur les contenus et services offerts sur les
          sites auxquels le visiteur à accès à partir d’hyperliens de son site
          festival.Everial.fr et sur l’utilisation qui en est fait par
          l’utilisateur. Le recours à ces sites par l’utilisateur relève de sa seule
          responsabilité.
        </p>
        <p style={{ fontWeight: 400 }}>
          Les utilisateurs et visiteurs du site Web ne peuvent mettre en place un
          hyperlien en direction de ce site sans l’autorisation expresse et préalable
          de la société&nbsp;Everial.
        </p>
        <p style={{ fontWeight: 400 }}>
          Everial met tout en œuvre pour offrir aux utilisateurs des informations
          et/ou outils disponibles et vérifiés, mais ne saurait en aucun cas être tenu
          pour responsable des erreurs, d’une absence de disponibilité des
          informations et/ou de la présence de virus sur son site.
        </p>
        <p style={{ fontWeight: 400 }}>&nbsp;</p>
      </div>
      </Grid>
    </Grid>
    </Container>
  );
};
