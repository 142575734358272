import { validateEmail, validatePhone, saveResponse } from '../Utilities';

class MessageParser {
  constructor(actionProvider, state, stateDatas) {
    this.actionProvider = actionProvider;
    this.state = state;
    this.stateDatas = stateDatas;
  }

  parse = (message) => {
    message = message.trim();

    const botMessages = this.state.messages.filter(item => {
      return item.type === 'bot' && item.key != undefined;
    });
    const nbrBotMessages = Object.values(botMessages).length;
    const lastBotMessage = botMessages[nbrBotMessages - 1];

    // Check validation
    let isValidate = true;
    if (lastBotMessage.hasOwnProperty('key') && lastBotMessage.infoMessage.inputType === "email") {
        if (!validateEmail(message)) {
          isValidate = false;
          this.actionProvider.handleMessageError(lastBotMessage.infoMessage);
        }
    } else if (lastBotMessage.hasOwnProperty('key') && lastBotMessage.infoMessage.inputType === "telephone") {
        if (!validatePhone(message)) {
          isValidate = false;
          this.actionProvider.handleMessageError(lastBotMessage.infoMessage);
        }
    }

    if (isValidate) {
      saveResponse(lastBotMessage.infoMessage.champ_id, message, lastBotMessage.infoMessage.inputType);
      this.actionProvider.handleNextMessage(parseInt(lastBotMessage.infoMessage.ordre) + 1);
    }
  }
}

export default MessageParser;